exports.components = {
  "component---src-knowledge-ako-kupit-a-zaregistrovat-domenu-tsx": () => import("./../../../src/knowledge/ako-kupit-a-zaregistrovat-domenu.tsx" /* webpackChunkName: "component---src-knowledge-ako-kupit-a-zaregistrovat-domenu-tsx" */),
  "component---src-knowledge-co-je-ttl-v-dns-zaznamoch-tsx": () => import("./../../../src/knowledge/co-je-ttl-v-dns-zaznamoch.tsx" /* webpackChunkName: "component---src-knowledge-co-je-ttl-v-dns-zaznamoch-tsx" */),
  "component---src-knowledge-nastavenie-emailovych-dns-zaznamov-mx-spf-dkim-dmarc-tsx": () => import("./../../../src/knowledge/nastavenie-emailovych-dns-zaznamov-mx-spf-dkim-dmarc.tsx" /* webpackChunkName: "component---src-knowledge-nastavenie-emailovych-dns-zaznamov-mx-spf-dkim-dmarc-tsx" */),
  "component---src-knowledge-sprava-dns-zaznamov-tsx": () => import("./../../../src/knowledge/sprava-dns-zaznamov.tsx" /* webpackChunkName: "component---src-knowledge-sprava-dns-zaznamov-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-domeny-tsx": () => import("./../../../src/pages/domeny.tsx" /* webpackChunkName: "component---src-pages-domeny-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kosik-tsx": () => import("./../../../src/pages/kosik.tsx" /* webpackChunkName: "component---src-pages-kosik-tsx" */),
  "component---src-pages-kosik-vysledok-tsx": () => import("./../../../src/pages/kosik-vysledok.tsx" /* webpackChunkName: "component---src-pages-kosik-vysledok-tsx" */),
  "component---src-pages-overenie-domeny-tsx": () => import("./../../../src/pages/overenie-domeny.tsx" /* webpackChunkName: "component---src-pages-overenie-domeny-tsx" */),
  "component---src-pages-vseobecne-obchodne-podmienky-tsx": () => import("./../../../src/pages/vseobecne-obchodne-podmienky.tsx" /* webpackChunkName: "component---src-pages-vseobecne-obchodne-podmienky-tsx" */),
  "component---src-pages-webhosting-tsx": () => import("./../../../src/pages/webhosting.tsx" /* webpackChunkName: "component---src-pages-webhosting-tsx" */),
  "component---src-templates-knowledge-tsx": () => import("./../../../src/templates/knowledge.tsx" /* webpackChunkName: "component---src-templates-knowledge-tsx" */)
}

